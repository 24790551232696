.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiDrawer-paperAnchorLeft{
  background:linear-gradient(-45deg, #114ebd, #0f3d6e) !important
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.react-dropdown-select-input{
  font-size: 14px !important;
  font-family: 'Poppins';
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.react-dropdown-select-dropdown{
  text-align: left;
}
.MuiFormControlLabel-label{
  font-size: 14px !important;
}
/* .Leftcol ul li a p{
  font-family: 'Poppins';
  font-size: 16px;
}
.Leftcol ul li a{
  text-decoration: none;
  font-family: 'Poppins';
  font-size: 16px;
} */
.ljydVP{
  display: none;
}
.react-dropdown-select-content{
  height: 20px;
  font-size: 14px;
  font-family: 'Poppins';
  width: 90%;
}
.react-dropdown-select-content span{
  white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.react-dropdown-select-input{
  font-family: 'Poppins' !important;
  font-size: 14px !important;
}

.MuiInputBase-input{
  font-size: 14px !important;
}
.react-dropdown-select-dropdown span{
  font-size: 14px;
  font-family: 'Poppins';
}
.leftnav a:hover{
  text-decoration: none;
  background-color: #0f3d6e;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ResponsiveNav{
  background-color: #0f3d6e;
  -ms-transform: rotate(90deg); /* IE 9 */
  transform: rotate(90deg);
  display: none;
  position: fixed;
  right: -20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 120px;
  z-index: 99;
}
.ResponsiveNav2{
  display: none;
  background-color: #0f3d6e;
  position: absolute;
  left: 5px;
  border-radius: 5px;
  top: 0px;
  z-index: 99;
}
.react-confirm-alert-overlay{
  z-index: 99999999999 !important;
}
.engagement-btn{
  width:100%;
  margin-top: 20px;
  display: flex;
}
.engagement-btn:hover{
  background-color: #04101c !important;
  color: #fff !important;
}
@media only screen and (max-width: 820px) {
  .ResponsiveNav{
    display: block;
  }
  .ResponsiveNav2{
    display: block;
  }
  .engagement-btn{
    width: 90%;
    margin-left: 5% !important;
  }
}
@media only screen and (max-width: 768px) {
  .ResponsiveNav{
    display: block;
  }
  .ResponsiveNav2{
    display: block;
  }
  .engagement-btn{
    width: 90%;
    margin-left: 5% !important;
  }
}

